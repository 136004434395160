import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"

export const Animation = ({ lottieFile, loop, className }) => {
  const ref = useRef(null)
  const [lottie, setLottie] = useState(null)

  useEffect(() => {
    import("lottie-web").then(Lottie => setLottie(Lottie.default))
  }, [])

  useEffect(() => {
    if (lottie && ref.current && lottieFile) {
      const animation = lottie.loadAnimation({
        container: ref.current,
        renderer: "svg",
        loop: loop,
        autoplay: true,
        // path to your animation file, place it inside public folder
        path: lottieFile,
      })

      return () => animation.destroy()
    }
  }, [lottie])

  return <div ref={ref} className={className + " w-full h-full"} />
}

Animation.propTypes = {
  lottieFile: PropTypes.string,
  loop: PropTypes.bool,
}

Animation.defaultProps = {
  loop: false,
}
