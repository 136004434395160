import styled from "styled-components"

export const FadeEdge = styled.div`
  position: absolute;
  width: 160px;
  height: 100%;
  background: linear-gradient(90deg, #E3EFFE 0%, rgba(227, 239, 254, 0) 100%);
  left: ${({ reversed }) => !reversed && '0'};
  right: ${({ reversed }) => reversed && '0'};
  top: 0;
  z-index: 10;
  transform: ${({ reversed }) => reversed && 'matrix(-1, 0, 0, 1, 0, 0)'};
  pointer-events: none;

  @media (max-width: 1023px) {
    display: none;
  }
`

export const QuoteCard = styled.div`
  min-height: 440px;
  max-width: 934px;
`