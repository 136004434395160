import React from "react"
import PropTypes from "prop-types"

function PercentIcon({ width, height, ...props }) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 48 48"
    >
      <path
        className="fill-current"
        d="M24 0a24 24 0 100 48 24 24 0 000-48zm0 44.4A20.4 20.4 0 1144.4 24 20.424 20.424 0 0124 44.4z"
      ></path>
      <path
        className="fill-current"
        d="M16.103 16.728a2.28 2.28 0 11-.247 4.553 2.28 2.28 0 01.247-4.553zm0-3.6a5.88 5.88 0 100 11.76 5.88 5.88 0 000-11.76zM31.802 26.64a2.4 2.4 0 11.24 4.792 2.4 2.4 0 01-.24-4.792zm0-3.6a5.88 5.88 0 100 11.76 5.88 5.88 0 000-11.76zM32.136 13.344H28.92a.935.935 0 00-.792.456L15.432 33.84a.407.407 0 000 .24.48.48 0 00.48.48h3.168a.96.96 0 00.72-.36l12.672-19.992a.505.505 0 000-.456.527.527 0 00-.48-.312l.144-.096z"
      ></path>
    </svg>
  );
}

PercentIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

PercentIcon.defaultProps = {
  width: 48,
  height: 48,
}

export default PercentIcon;
