import React from "react"
import PropTypes from "prop-types"
import { classes } from "../utils/paloma-helpers"

const TextInput = ({ form, field, label, onInputChange, textArea, dark, name, ...props }) => {
  const { className } = props

  const styleOptions = {
    "bg-gray-300 bg-opacity-20 placeholder-gray-300 text-white active:opacity-100 focus:ring-1 focus:ring-white": dark,
    "bg-white placeholder-gray-300 text-blue focus:ring-1 focus:ring-blue": !dark,
  }

  const handleChange = event => {
    event.preventDefault()
    let newForm = form
    if (form[field] !== undefined) {
      newForm[field] = event.target.value
      onInputChange({ ...newForm })
    }
  }

  return (
    <div className={className}>
      <label htmlFor={label} className="sr-only">
        {label}
      </label>

      {textArea ? (
        <textarea
          type="text"
          name={name || label}
          id={label}
          className={classes(styleOptions, " shadow-sm block w-full h-32 rounded-lg px-4 py-3 focus:outline-none")}
          placeholder={label}
          onChange={handleChange}
        />
      ) : (
        <input
          type="text"
          name={name || label}
          id={label}
          className={classes(styleOptions, " shadow-sm block w-full rounded-lg h-12 px-4 focus:outline-none")}
          placeholder={label}
          onChange={handleChange}
        />
      )}
    </div>
  )
}

export default TextInput

TextInput.propTypes = {
  /**
   * The form object attached to this component
   */
  form: PropTypes.object.isRequired,
  /**
   * The form field tied to this instance
   */
  field: PropTypes.string.isRequired,
  /**
   * Placeholder label appearing on the input
   */
  label: PropTypes.string,
  /**
   * Name explicit in form
   */
  name: PropTypes.string,
  /**
   * Function called whenever the input's vale is updated (i.e., setForm)
   */
  onInputChange: PropTypes.func.isRequired,
  /**
   * Flag to determine whether the input is a larger text area
   */
  textArea: PropTypes.bool,
  /**
   * Flag to determine the input's styling
   */
  dark: PropTypes.bool,
}
