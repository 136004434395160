import React from "react"
import PropTypes from "prop-types"

function HapinessIcon({ width, height, ...props }) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 48 48"
    >
      <path
        className="fill-current"
        d="M24 3.6A20.4 20.4 0 113.6 24 20.424 20.424 0 0124 3.6zM24 0a24 24 0 100 48 24 24 0 000-48z"
      ></path>
      <path
        fill="#66ACFF"
        d="M10.703 21.312l-1.368-1.656a.816.816 0 01.264-1.2 7.872 7.872 0 018.136 0 .842.842 0 01.168 1.224l-1.344 1.656a.84.84 0 01-1.104.168 3.936 3.936 0 00-3.648.096.864.864 0 01-1.104-.288zM31.559 21.312l-1.368-1.656a.816.816 0 01.168-1.2 7.871 7.871 0 018.136 0 .842.842 0 01.168 1.224l-1.344 1.656a.84.84 0 01-1.104.168 3.936 3.936 0 00-3.552 0 .864.864 0 01-1.104-.192zM31.874 28.8l-1.44-.864a1.13 1.13 0 00-.504-.144 1.031 1.031 0 00-.792.36c-.144.168-.24.36-.408.528a6.768 6.768 0 01-9.336 0l-.408-.528a.962.962 0 00-.792-.36.888.888 0 00-.504.144l-1.44.864a.96.96 0 00-.48.84.84.84 0 00.144.384c.266.413.563.806.888 1.176a10.224 10.224 0 0014.4 0c.377-.404.722-.838 1.032-1.296.061-.112.102-.234.12-.36a.962.962 0 00-.48-.744z"
      ></path>
    </svg>
  );
}

HapinessIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

HapinessIcon.defaultProps = {
  width: 48,
  height: 48,
}

export default HapinessIcon;
