import React from "react"
import PropTypes from "prop-types"

function SustainabilityIcon({ width, height, ...props }) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 48 48">
      <g className="fill-current">
        <path d="M23.905 0a23.914 23.914 0 00-18.24 8.578V4.492a.955.955 0 00-.956-.956H3.036a.956.956 0 00-.956.956v9.844a.955.955 0 00.956.955h9.73a.934.934 0 00.931-.955v-1.673a.932.932 0 00-.932-.956h-5.02a20.416 20.416 0 0125.628-5.75 20.395 20.395 0 011.577 35.327A20.423 20.423 0 018.91 37.837 20.399 20.399 0 013.586 24.92a.956.956 0 00-.932-.908H.98A.978.978 0 000 25.016a24.004 24.004 0 004.537 13.068 24.022 24.022 0 0024.803 9.34 24.027 24.027 0 0012.036-6.827 24.008 24.008 0 004.696-26.073A24.015 24.015 0 0037.174 3.93 24.033 24.033 0 0023.904 0z"></path>
        <path d="M14.82 35.026l-1.96-1.959a.384.384 0 010-.573l14.343-14.336a.43.43 0 01.597 0l1.936 1.96a.384.384 0 010 .573L15.394 35.026a.383.383 0 01-.574 0z"></path>
        <path d="M31.675 16.438v8.243a3.007 3.007 0 01-1.838 2.796 3.012 3.012 0 01-1.15.238h-8.271v-8.267a3.01 3.01 0 013.012-3.01h8.247zm3.585-3.584H23.428a6.622 6.622 0 00-6.694 6.595v11.946h11.953a6.6 6.6 0 006.102-4.088c.33-.802.498-1.662.495-2.53V12.853h-.024z"></path>
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill="#fff" d="M0 0H48V48H0z"></path>
        </clipPath>
      </defs>
    </svg>
  )
}

SustainabilityIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

SustainabilityIcon.defaultProps = {
  width: 48,
  height: 48,
}

export default SustainabilityIcon
