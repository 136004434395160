import React, { useRef } from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { useIntl } from "gatsby-plugin-intl"

import { Animation } from "./LottieAnimation"
import Container from "./containers/Container"
import { useVideo } from "./hooks/useVideo"

const MediaContainer = styled.div`
  width: 680px;
  height: 680px;

  @media (max-width: 768px) {
    width: 100%;
    height: auto;
    min-height: 87vw;
  }

  @media (max-width: 340px) {
    width: 100%;
    height: auto;
    min-height: 85vw;
  }
`

const TitleDescription = styled.span`
  a {
    color: #66acff;
  }
`

const CenterTitle = ({ content }) => {
  const videoContainer = useRef()
  const { formatMessage } = useIntl()

  const { header, text, image, lottie, video } = content

  useVideo(videoContainer, video, true, "w-full sm:w-9/12 md:w-full")

  return (
    <Container className="flex flex-col items-center pt-28 lg:pt-40">
      <div className="flex flex-col items-center text-center space-y-6 lg:space-y-10 sm:max-w-sm md:max-w-lg lg:max-w-screen-sm mb-11 lg:mb-20">
        <h2 className="text-h4 lg:text-h2 text-blue">{formatMessage({ id: header })}</h2>
        <TitleDescription className="text-p2 lg:text-d2 text-gray-500" dangerouslySetInnerHTML={{ __html: formatMessage({ id: text }) }} />
      </div>
      <MediaContainer
        ref={videoContainer}
        className={"relative z-10 justify-center mb-11 lg:mb-24 " + (image || lottie || video ? "flex" : "hidden")}
      >
        {image && <img alt={text} src={image} className="w-full sm:w-9/12 md:w-full" />}
        {lottie && <Animation lottieFile={lottie} className="w-full sm:w-9/12 md:w-full" />}
      </MediaContainer>
    </Container>
  )
}

CenterTitle.propTypes = {
  content: PropTypes.object,
}

export default CenterTitle
