/* eslint-disable no-useless-concat */
import React from 'react'
import PropTypes from 'prop-types'

/**
 * Simple text to display under failing requirements
 */
const ValidationMessage = ({ conditions, ...props }) => {
  const { className, ...addonProps } = props
  const invalidConditions = conditions.filter((c) => c.isInvalid)

  if (!invalidConditions) return null

  return (
    <div {...addonProps} className={className}>
      {invalidConditions.map((condition) => {
        return (
          <span key={condition.text} className="block text-xs text-danger" data-testid="errorMessage">
            {condition.text}
          </span>
        )
      })}
    </div>
  )
}

ValidationMessage.propTypes = {
  /**
   * A list of conditions to be met
   */
  conditions: PropTypes.array
}

ValidationMessage.defaultProps = {
  conditions: []
}

export default ValidationMessage
