import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { navigate, useIntl } from "gatsby-plugin-intl"

// Components
import Container from "../../components/containers/Container"
import PalButton from "../../components/buttons/PalButton"
import Slider from "../../components/Slider/Slider"
import { useReverseLanguage } from "../../components/hooks/useReverseLanguage"

const BackgroundShape = styled.div`
  position: absolute;
  width: 722px;
  height: 722px;
  border-radius: ${({ reversed }) => (reversed ? "0 400px 0 0" : "0 0 0 400px")};
  left: ${({ reversed }) => (reversed ? "0" : "50%")};
  right: ${({ reversed }) => (reversed ? "50%" : "0")};
  top: ${({ reversed }) => !reversed && "0"};
  bottom: ${({ reversed }) => reversed && "0"};
  z-index: -20;

  @media (max-width: 1023px) {
    display: none;
  }
`

const TextIconsColumns = ({ content }) => {
  const { header, textCards, type, buttonOneText, buttonOneLink, buttonTwoText, buttonTwoLink } = content
  const { formatMessage } = useIntl()
  const reversed = type === "reversed"

  return (
    <Container
      fullWidth
      className="flex flex-col lg:items-center text-white relative overflow-hidden py-10 lg:pt-32 lg:pb-24 max-w-max lg:max-w-full"
    >
      <BackgroundShape className="bg-green-light bg-opacity-40 2xl:hidden" reversed={reversed} />

      <h3
        className={
          "font-extended text-blue lg:text-center pr-10 mb-10 lg:pr-0 lg:mb-20 max-w-lg " + (useReverseLanguage() ? "text-right" : "text-left")
        }
      >
        {formatMessage({ id: header })}
      </h3>
      <div className="hidden lg:grid lg:grid-cols-2 gap-y-24 gap-x-32 lg:mx-auto mb-16 lg:mb-20">
        {textCards.map((card, index) => {
          return (
            <div className="flex flex-col justify-start max-w-sm" key={index}>
              <div className="text-blue-highlight mb-8">{card.icon}</div>
              <h6 className="font-extended text-blue mb-4">{formatMessage({ id: card.title })}</h6>
              <p className="text-p3 text-gray-500">{formatMessage({ id: card.text })}</p>
            </div>
          )
        })}
      </div>
      <div className="max-w-lg lg:hidden">
        <Slider element="textIcons-slider" controls options={{}}>
          {textCards.map((card, index) => {
            return (
              <li key={index}>
                <div className="flex flex-col justify-start max-w-sm">
                  <div className="text-blue-highlight mb-8">{card.icon}</div>
                  <h6 className="font-extended text-blue mb-4">{formatMessage({ id: card.title })}</h6>
                  <p className="text-p3 text-gray-500">{formatMessage({ id: card.text })}</p>
                </div>
              </li>
            )
          })}
        </Slider>
      </div>
      {buttonOneText && (
        <div className="flex flex-col lg:flex-row gap-5 justify-center max-w-lg lg:mt-6">
          <PalButton className="lg:w-52" label={formatMessage({ id: buttonOneText })} action={() => navigate(buttonOneLink)} />
          {buttonTwoText && (
            <PalButton className="lg:w-52" variant="outline" label={formatMessage({ id: buttonTwoText })} action={() => navigate(buttonTwoLink)} />
          )}
        </div>
      )}
    </Container>
  )
}

TextIconsColumns.propTypes = {
  content: PropTypes.object.isRequired,
}

export default TextIconsColumns
