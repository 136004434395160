import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { useIntl } from "gatsby-plugin-intl"
import { useReverseLanguage } from "./hooks/useReverseLanguage"

const IconTextWrapper = styled.div`
  max-width: 697px;
  width: 100%;
`

const SmallIconText = ({ icon, headline, description, alt }) => {
  const { formatMessage } = useIntl()
  return (
    <IconTextWrapper className="flex flex-col lg:flex-row justify-center items-center gap-x-0 lg:gap-x-16 space-y-5 lg:space-y-0">
      <img alt={alt} src={icon} className="w-24 sm:w-48" />
      <div
        className={
          "flex flex-col items-center lg:items-start justify-center text-center space-y-4 w-56 xs:w-72 sm:w-96 lg:w-96 " +
          (useReverseLanguage() ? "lg:text-right" : "lg:text-left")
        }
      >
        {headline && <h6 className="text-blue font-extended">{formatMessage({ id: headline })}</h6>}
        <p className="text-gray-500 text-p3 sm:text-base">{formatMessage({ id: description })}</p>
      </div>
    </IconTextWrapper>
  )
}

SmallIconText.propTypes = {
  icon: PropTypes.string,
  headline: PropTypes.string,
  description: PropTypes.string,
}

export default SmallIconText
