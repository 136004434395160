import React from "react"
import PropTypes from "prop-types"

import CenterTitle from "../../components/CenterTitle"
import Container from "../../components/containers/Container"
import Accordion from "../Accordion/Accordion"

const FAQ = ({ content }) => {
  const { headerContent, questions } = content

  return (
    <Container className="py-10 lg:py-40">
      <CenterTitle content={headerContent} />
      <Accordion items={questions} />
    </Container>
  )
}

FAQ.propTypes = {
  content: PropTypes.object,
}

export default FAQ
