import React from "react"

import cover from "../../assets/images/business/01_heroBusiness.jpg"
import scooterStreet from "../../assets/images/business/01_monthlyPass.jpg"
import linkedInIcon from "../../assets/images/business/linkedinicon.svg"
import SustainabilityIcon from "../../components/icons/SustainabilityIcon"
import HapinessIcon from "../../components/icons/HapinessIcon"
import PercentIcon from "../../components/icons/PercentIcon"
import TrainIcon from "../../components/icons/TrainIcon"

import howToOne from "../../assets/images/business/howToOne.svg"
import howToTwo from "../../assets/images/business/howToTwo.svg"
import howToThree from "../../assets/images/business/howToThree.svg"

import quote1 from "../../assets/images/business/quote1.svg"
import quote2 from "../../assets/images/business/quote2.svg"
import quote3 from "../../assets/images/business/quote3.svg"

export const businessContent = {
  seo: {
    metaTitle: "metaTitleTierForBusiness",
    metaDescription: "metaDescriptionTierForBusiness",
  },
  mainHero: {
    headline: "businessMainHeroHeadline",
    subline: "businessMainHeroSubline",
    buttonText: "tierBusinessCta",
    link: {
      link: "#business-contact",
      anchor: true,
    },
    type: "two-column",
    media: {
      type: "image",
      link: cover,
      alt: "tier-business-man",
    },
  },
  textIcons: {
    header: "businessTextIconsHeader",
    textCards: [
      {
        icon: <SustainabilityIcon />,
        title: "businessTextIconsTextCardsTitleOne",
        text: "businessTextIconsTextCardsTextOne",
      },
      {
        icon: <HapinessIcon />,
        title: "businessTextIconsTextCardsTitleTwo",
        text: "businessTextIconsTextCardsTextTwo",
      },
      {
        icon: <PercentIcon />,
        title: "businessTextIconsTextCardsTitleThree",
        text: "businessTextIconsTextCardsTextThree",
      },
      {
        icon: <TrainIcon />,
        title: "businessTextIconsTextCardsTitleFour",
        text: "businessTextIconsTextCardsTextFour",
      },
    ],
    type: "normal",
  },
  howDoesItWork: {
    header: "businessHowToHeader",
    instructions: [
      {
        icon: howToOne,
        description: "businessHowToDescriptionOne",
      },
      {
        icon: howToTwo,
        description: "businessHowToDescriptionTwo",
      },
      {
        icon: howToThree,
        description: "businessHowToDescriptionThree",
      },
    ],
  },
  textImage: {
    headline: "businessTextImageHeadline",
    list: {
      listTitle: "businessTextImageDescription",
      items: [
        "businessTextImageListOne",
        "businessTextImageListTwo",
        "businessTextImageListThree",
        "businessTextImageListFour",
        "businessTextImageListFive",
      ],
    },
    buttonText: "businessMainHeroButton",
    link: {
      link: "#business-contact",
      anchor: true,
    },
    media: { type: "image", link: scooterStreet, alt: "tier-women-riding-scooters" },
    type: "normal",
    backgroundColor: "bg-blue-background",
  },
  textBold: {
    header: "businessTextBoldHeader",
    quoteCards: [
      {
        portrait: quote1,
        quotee: "businessTextBoldQuoteCardOneQuotee",
        workplace: "businessTextBoldQuoteCardOneWorkplace",
        quote: "businessTextBoldQuoteCardOneQuote",
      },
      {
        portrait: quote2,
        workplace: "businessTextBoldQuoteCardTwoWorkplace",
        quote: "businessTextBoldQuoteCardTwoQuote",
      },
      {
        portrait: quote3,
        workplace: "businessTextBoldQuoteCardThreeWorkplace",
        quote: "businessTextBoldQuoteCardThreeQuote",
      },
    ],
  },
  textIcon: {
    description: "businessTextCtaDescription",
    backgroundColor: "blue",
    textColor: "white",
    iconPosition: "left",
    icon: linkedInIcon,
    alt: "tier-linkedin-icon",
    buttonText: "LinkedInCTAtier4business",
    buttonLink: {
      link: "https://www.linkedin.com/company/tiermobility/",
      force: true,
    },
  },
  contactForm: {
    id: "business-contact",
    headline: "businessContactFormHeadline",
    buttonText: "businessContactFormButton",
    companyLabel: "businessContactFormFieldOne",
    countryLabel: "businessContactFormFieldTwo",
    sizeLabel: "businessContactFormFieldThree",
    nameLabel: "businessContactFormFieldFour",
    positionLabel: "businessContactFormFieldEight",
    emailLabel: "businessContactFormFieldSix",
    phoneLabel: "businessContactFormFieldSeven",
    messageLabel: "businessContactFormFieldNine",
  },
  faq: {
    headerContent: {
      header: "businessFaqHeader",
      text: "businessFaqDescription",
    },
    questions: [
      {
        header: "businessFaqQuestionOne",
        body: "businessFaqAnswerOne",
      },
      {
        header: "businessFaqQuestionTwo",
        body: "businessFaqAnswerTwo",
      },
      {
        header: "businessFaqQuestionThree",
        body: "businessFaqAnswerThree",
      },
      {
        header: "businessFaqQuestionFour",
        body: "businessFaqAnswerFour",
        link: {
          name: "whereToFindUsLink",
          link: "/where-to-find-us",
        },
      },
      {
        header: "businessFaqQuestionFive",
        body: "businessFaqAnswerFive",
      },
      {
        header: "businessFaqQuestionSix",
        body: "businessFaqAnswerSix",
      },
      {
        header: "businessFaqQuestionSeven",
        body: "businessFaqAnswerSeven",
      },
      {
        header: "businessFaqQuestionNine",
        body: "businessFaqAnswerNine",
      },
    ],
  },
}
