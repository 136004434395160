import React, { useEffect } from "react"
import SEO from "../components/SEO"
import { useIntl } from "gatsby-plugin-intl"

// Components
import HeroMedia from "../modules/HeroMedia/HeroMedia"
import TextImage from "../modules/TextImage/TextImage"
import ContactForm from "../modules/ContactForm/ContactForm"
import TextBold from "../modules/TextBold/TextBold"
import TextIcons from "../modules/TextIcons/TextIcons"
import TextIcon from "../modules/TextIcon/TextIcon"
import Container from "../components/containers/Container"
import FAQ from "../modules/FAQ/FAQ"
import SmallIconText from "../components/SmallIconText"

// Helpers & Content
import { businessContent } from "../utils/content/businessContent"
import { fireEvent } from "../utils/helpers"
import { useReverseLanguage } from "../components/hooks/useReverseLanguage"

// markup
const IndexPage = () => {
  const { seo, mainHero, textImage, contactForm, textIcon, textBold, textIcons, howDoesItWork, faq } = businessContent
  const { formatMessage } = useIntl()

  useEffect(() => {
    fireEvent("nav-scheme", "dark")
  }, [])

  return (
    <div className="relative overflow-hidden">
      <SEO title={formatMessage({ id: seo.metaTitle })} description={formatMessage({ id: seo.metaDescription })} />
      <HeroMedia content={mainHero} />
      <TextIcons content={textIcons} />
      <Container className="flex flex-col lg:items-center py-20 lg:pt-32 lg:pb-48">
        <h3 className={"font-extended text-blue text-center mb-20 max-w-xl mx-auto " + (useReverseLanguage() ? "pl-10 lg:pl-0" : "")}>
          {formatMessage({ id: howDoesItWork.header })}
        </h3>
        <div className="flex flex-col items-center justify-center space-y-16 lg:space-y-36">
          {howDoesItWork.instructions.map((instruction, index) => {
            return <SmallIconText key={index} alt={"tier-business-" + index + 1} icon={instruction.icon} description={instruction.description} />
          })}
        </div>
      </Container>
      <TextImage content={textImage} />
      <TextBold content={textBold} />
      <ContactForm content={contactForm} />
      <TextIcon content={textIcon} />
      <FAQ content={faq} />
    </div>
  )
}

export default IndexPage
