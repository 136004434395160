import React from "react"
import PropTypes from "prop-types"
import { useIntl } from "gatsby-plugin-intl"

// Components
import Slider from "../../components/Slider/Slider"
import QuoteMarkIcon from "../../components/icons/QuoteMarkIcon"
import { FadeEdge, QuoteCard } from "./TextBold.styles"

import * as sliderStyles from "./TextBold.module.css"

const TextBold = ({ content }) => {
  const { header, quoteCards } = content
  const { formatMessage, locale } = useIntl()

  return (
    <div className="flex flex-col items-center text-white bg-blue-background relative px-6 py-10 lg:px-0 lg:py-32 overflow-hidden">
      <FadeEdge />
      <FadeEdge reversed />

      <h5 className="font-extended text-center text-h5 text-blue mb-10 lg:mb-20 max-w-2xl">{formatMessage({ id: header })}</h5>
      <div className="w-full mx-auto">
        <Slider
          element="textBold-slider"
          controls
          trackStyles={sliderStyles.track}
          slideStyles={sliderStyles.slide}
          options={{
            type: "carousel",
            focusAt: "center",
            perView: 1,
            gap: 48,
            direction: locale === "ar" ? "rtl" : "ltr",
            classes: {
              carousel: sliderStyles.slider,
              slider: sliderStyles.slider,
              activeNav: sliderStyles.bulletActive,
            },
          }}
        >
          {quoteCards.map((card, index) => {
            return (
              <li key={index}>
                <QuoteCard className="flex flex-col lg:flex-row space-y-8 lg:gap-x-24 bg-background rounded-2xl px-6 pt-6 pb-16 lg:px-16 lg:pt-14">
                  <div className="flex flex-col items-center">
                    <img src={card.portrait} className="mb-8" />
                    {card.quotee && <p className="text-p3 text-gray-500 font-bold">{formatMessage({ id: card.quotee })}</p>}
                    <p className={"text-p3 text-gray-500 " + (card.quotee ? "" : "font-bold")}>{formatMessage({ id: card.workplace })}</p>
                  </div>
                  <div>
                    <QuoteMarkIcon className="text-orange-highlight hidden lg:block" />
                    <h6 className={"font-extended text-d2 text-blue max-w-lg mt-3 hidden lg:block "}>{formatMessage({ id: card.quote })}</h6>
                    <h6 className={"font-extended text-d2 text-blue mt-3 lg:hidden "}>{`"${formatMessage({
                      id: card.quote,
                    })}"`}</h6>
                    <QuoteMarkIcon className="text-orange-highlight hidden lg:block transform rotate-180 relative float-right" />
                  </div>
                </QuoteCard>
              </li>
            )
          })}
        </Slider>
      </div>
    </div>
  )
}

TextBold.propTypes = {
  content: PropTypes.object.isRequired,
}

export default TextBold
