import React from "react"
import PropTypes from "prop-types"

function TrainIcon({ width, height, ...props }) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 48 48"
    >
      <path
        className="fill-current"
        d="M40.883 6.342a.818.818 0 01.818.817v31.28a.816.816 0 01-.818.816H7.216a.819.819 0 01-.914-.817V7.208a.816.816 0 01.914-.866h33.667zm0-3.94H7.216A4.812 4.812 0 003.815 3.81a4.802 4.802 0 00-1.409 3.397v31.231c0 1.275.507 2.497 1.409 3.398a4.812 4.812 0 003.4 1.407h33.668a4.812 4.812 0 003.401-1.407 4.802 4.802 0 001.409-3.398V7.208a4.802 4.802 0 00-1.409-3.398 4.812 4.812 0 00-3.4-1.408z"
      ></path>
      <path
        className="fill-current"
        d="M34.414 0H13.59a1.829 1.829 0 00-1.804 1.802v1.802h24.433V1.802A1.801 1.801 0 0034.414 0zM37.276 32.24h-2.67a.962.962 0 00-.962.961v1.682c0 .53.431.96.962.96h2.67c.531 0 .962-.43.962-.96V33.2c0-.53-.431-.96-.962-.96zM13.37 32.24H10.7a.961.961 0 00-.962.961v1.682c0 .53.43.96.962.96h2.67c.53 0 .962-.43.962-.96V33.2c0-.53-.431-.96-.963-.96zM1.515 47.736l-1.25-1.273a.89.89 0 010-1.274l4.81-4.805 2.549 2.547-4.81 4.805a.962.962 0 01-1.299 0zM45.188 47.736l-4.81-4.805 2.55-2.547 4.81 4.805a.89.89 0 010 1.273l-1.25 1.274a.962.962 0 01-1.3 0zM13.348 13.574h21.307v12.684H13.35V13.574zM12.026 9.97a2.406 2.406 0 00-2.405 2.402v15.28a2.401 2.401 0 002.405 2.402h24.048a2.406 2.406 0 002.405-2.402V12.276a2.401 2.401 0 00-2.285-2.402l-24.168.096z"
      ></path>
    </svg>
  );
}

TrainIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

TrainIcon.defaultProps = {
  width: 48,
  height: 48,
}

export default TrainIcon;
