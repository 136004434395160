import React, { useState } from "react"
import PropTypes from "prop-types"
import { useIntl } from "gatsby-plugin-intl"

// Components
import Container from "../../components/containers/Container"
import PalButton from "../../components/buttons/PalButton"
import TextInput from "../../components/TextInput"
import PalDropdown from "../../components/PalDropdown"

// Helpers
import { countryList } from "../../utils/helpers"

const ContactForm = ({ content }) => {
  const { formatMessage } = useIntl()
  const { id, headline, companyLabel, countryLabel, sizeLabel, nameLabel, positionLabel, emailLabel, phoneLabel, messageLabel, buttonText } = content
  const [contactForm, setContactForm] = useState({
    company: "",
    country: "",
    companySize: "",
    name: "",
    position: "",
    email: "",
    phone: "",
    query: "",
  })

  return (
    <Container id={id} fullWidth className="bg-blue-background flex items-center text-blue pt-20 pb-32 md:py-40">
      <div className="flex flex-col max-w-xl m-auto z-10">
        <h5 className="font-extended text-center w-full pr-4 md:pr-12 max-w-xs md:max-w-none mx-auto md:mx-none">
          {formatMessage({ id: headline })}
        </h5>
        <form
          name="business"
          method="post"
          netlify-honeypot="bot-field"
          data-netlify="true"
          action="/business?success=true"
          className="flex flex-wrap gap-x-3 space-y-6 mt-12 md:mt-16"
        >
          <input type="hidden" name="bot-field" />
          <input type="hidden" name="form-name" value="business" />
          <input type="hidden" name="country" value={contactForm.country} />
          <input type="hidden" name="companySize" value={contactForm.companySize} />
          <TextInput
            name="company"
            form={contactForm}
            onInputChange={setContactForm}
            field="company"
            label={formatMessage({ id: companyLabel })}
            className="w-full"
          />
          <div className="min-w-full space-y-6 sm:space-y-0 gap-x-3 flex flex-col sm:flex-row">
            <div className="w-full sm:w-1/2">
              <PalDropdown
                placeholder={formatMessage({ id: countryLabel })}
                options={countryList}
                onChange={value => setContactForm({ ...contactForm, country: value })}
              />
            </div>
            <div className="w-full sm:w-1/2">
              <PalDropdown
                placeholder={formatMessage({ id: sizeLabel })}
                options={["1-20", "21-50", "51-100", "101-250", "250+"]}
                onChange={value => setContactForm({ ...contactForm, companySize: value })}
              />
            </div>
          </div>
          <TextInput
            name="name"
            form={contactForm}
            onInputChange={setContactForm}
            field="name"
            label={formatMessage({ id: nameLabel })}
            className="flex-grow"
          />
          <TextInput
            name="position"
            form={contactForm}
            onInputChange={setContactForm}
            field="position"
            label={formatMessage({ id: positionLabel })}
            className="flex-grow"
          />
          <TextInput
            name="email"
            form={contactForm}
            onInputChange={setContactForm}
            field="email"
            label={formatMessage({ id: emailLabel })}
            className="flex-grow"
          />
          <TextInput
            name="phone"
            form={contactForm}
            onInputChange={setContactForm}
            field="phone"
            label={formatMessage({ id: phoneLabel })}
            className="flex-grow"
          />
          <TextInput
            name="message"
            form={contactForm}
            onInputChange={setContactForm}
            field="query"
            label={formatMessage({ id: messageLabel })}
            textArea
            className="w-full"
          />
          <PalButton type="submit" className="w-full md:w-1/2 mt-6 md:mt-12 md:mx-auto" label={formatMessage({ id: buttonText })} color="primary" />
        </form>
      </div>
    </Container>
  )
}

ContactForm.propTypes = {
  /**
   * Prop for the background color, passed as a tailwind class
   */
  content: PropTypes.object.isRequired,
}

export default ContactForm
