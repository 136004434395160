/* eslint-disable no-useless-concat */
import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import ValidationMessage from "./ValidationMessage/ValidationMessage"
import { classes } from "../utils/paloma-helpers"

import ChevronDown from "../components/icons/ChevronDown"

import check from "../assets/images/check.svg"

// @todo: Figure out keypresses

/**
 * Basic Input Field
 */
const PalDropdown = ({ label, options, placeholder, conditions, onChange, ...props }) => {
  const { ...addonProps } = props
  const optionsRef = useRef()
  const ref = useRef()
  const [optionVisible, setOptionVisible] = useState(false)
  const [focused, setFocused] = useState("")
  const [selected, setSelected] = useState(placeholder || "")
  const optionStyles = (index, highlight) => ({
    "text-focus": focused === index,
    "hover:text-blue": index > 0 || (index === 0 && selected.value),
    "text-blue-informative hover:text-blue-informative hover:bg-blue-background": highlight,
    "text-gray-300 hover:text-gray-300 hover:bg-blue-background active:text-white active:bg-blue-informative": !highlight,
  })
  const extendedOptions = [placeholder, ...options]

  const isInvalid = () => {
    return conditions.some(c => c.isInvalid)
  }

  const handleKeys = e => {
    e.preventDefault()

    if (e.key === "ArrowUp" || e.key === "ArrowDown" || e.key === "Space") {
      setOptionVisible(true)
      optionsRef.current.focus()
    }

    if (e.key === "ArrowUp") {
      let next = focused - 1 >= 0 ? focused - 1 : extendedOptions.length - 1

      setFocused(next === 0 && !selected.value ? extendedOptions.length - 1 : next)
    }

    if (e.key === "ArrowDown") {
      let next = focused + 1 <= extendedOptions.length - 1 ? focused + 1 : 0

      setFocused(next === 0 && !selected.value ? 1 : next)
    }
  }

  const handleKeyboardNavigate = e => {
    e.preventDefault()

    if (e.key === "ArrowUp") {
      let next = focused - 1 >= 0 ? focused - 1 : extendedOptions.length - 1

      setFocused(next === 0 && !selected.value ? extendedOptions.length - 1 : next)
    }

    if (e.key === "ArrowDown") {
      let next = focused + 1 <= extendedOptions.length - 1 ? focused + 1 : 0

      setFocused(next === 0 && !selected.value ? 1 : next)
    }

    if (e.key === "Enter") {
      handleSelect(extendedOptions[focused])
    }

    if (e.key === "Space") {
      reset()
    }
  }

  const toggleOptions = () => {
    setOptionVisible(!optionVisible)
  }

  const activeOptionIndex = () => {
    return extendedOptions.findIndex(x => x.value === selected.value || x === selected.value)
  }

  const reset = () => {
    setOptionVisible(false)
    setFocused("")
  }

  const handleSelect = option => {
    if (onChange) onChange(selected)

    setSelected(option)
    reset()
  }

  const activeDescendant = `${label}-option-${activeOptionIndex}`

  const handleHideDropdown = event => {
    if (event.key === "Escape") {
      setOptionVisible(false)
    }
  }

  const handleClickOutside = event => {
    if (ref.current && !ref.current.contains(event.target)) {
      setOptionVisible(false)
    }
  }

  useEffect(() => {
    document.addEventListener("keydown", handleHideDropdown, true)
    document.addEventListener("click", handleClickOutside, true)
    return () => {
      document.removeEventListener("keydown", handleHideDropdown, true)
      document.removeEventListener("click", handleClickOutside, true)
    }
  })

  return (
    <div className="relative w-full" ref={ref} {...addonProps}>
      {label && (
        <span id={`${label}-label`} className={(isInvalid() ? "text-error " : "") + "font-semibold inline-block mb-3"}>
          {label}
        </span>
      )}
      <div
        id={`${label}-button`}
        role="button"
        className={
          "relative flex items-center focus:outline-none bg-white cursor-pointer shadow-sm rounded-lg h-12 px-4 " +
          (optionVisible ? "border border-blue " : " ") +
          (optionVisible ? "z-20" : "z-0")
        }
        aria-haspopup="listbox"
        aria-labelledby={`${label}-label ${label}-button`}
        aria-expanded={optionVisible}
        tabIndex="0"
        onClick={toggleOptions}
        onKeyUp={handleKeys}
      >
        <p className={(selected && selected !== placeholder ? "text-blue " : "text-gray-300 ") + "text-p3 pb-1 pr-6"}>{selected.value || selected}</p>
        <ChevronDown className={(optionVisible ? "rotate-180" : "rotate-0") + " transform transition-transform absolute right-4 text-gray-300"} />
        {/* <span className="material-icons absolute right-0 transform rotate-90 text-blue">→</span> */}
      </div>
      <ul
        className={
          (!optionVisible ? "hidden " : "") + "w-full max-h-60 shadow-dropdown rounded-lg absolute overflow-scroll left-0 z-10 -mt-2 pt-2 bg-white"
        }
        ref={optionsRef}
        role="listbox"
        tabIndex="-1"
        aria-labelledby={`${label}-label`}
        aria-activedescendant={activeDescendant}
        onFocus={() => {
          console.log("Focused Ul")
          setFocused(1)
        }}
        onClick={() => console.log("Clicked Ul")}
        onKeyUp={handleKeyboardNavigate}
      >
        {options.map((option, index) => {
          return (
            <li
              id={`${label}-option-${index}`}
              key={option.value || option}
              role="option"
              aria-selected={activeOptionIndex === index}
              className={classes(
                optionStyles(index, selected === option || selected === option.value),
                "px-5 cursor-pointer focus:ring-2 focus:ring-focus relative transition duration-75"
              )}
              onClick={() => handleSelect(option)}
            >
              <div className="py-3">{option.value || option}</div>
              {(selected === option || selected === option.value) && (
                <img alt="check" src={check} className="absolute right-5 top-0 bottom-0 m-auto" />
              )}
            </li>
          )
        })}
      </ul>
      {isInvalid && <ValidationMessage conditions={conditions} />}
    </div>
  )
}

PalDropdown.propTypes = {
  /**
   * List of options
   */
  options: PropTypes.array.isRequired,
  /**
   * Function to control model in parent component
   */
  onChange: PropTypes.func,
  /**
   * Identifier
   */
  label: PropTypes.string,
  /**
   * Placeholder text inside the input
   */
  placeholder: PropTypes.string.isRequired,
  /**
   * A list of conditions to be met for validty
   */
  conditions: PropTypes.array,
  /**
   * Function to execute when input is blured
   */
  onBlur: PropTypes.func,
}

PalDropdown.defaultProps = {
  label: "",
  conditions: [],
  placeholder: "",
  onBlur: () => null,
}

export default PalDropdown
