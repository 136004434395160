import React from "react"
import PropTypes from "prop-types"

function QuoteMarkIcon({ width, height, ...props }) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 29 17"
    >
      <path
        className="fill-current"
        d="M14.626.464H6.954l-6.72 16.24H10.65L14.626.464zm13.552 0h-7.672l-6.72 16.24h10.416L28.178.464z"
      ></path>
    </svg>
  );
}

QuoteMarkIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

QuoteMarkIcon.defaultProps = {
  width: 29,
  height: 17,
}

export default QuoteMarkIcon;
